import {
  h,
  createContext,
  FunctionComponent,
  ComponentType,
  ComponentChildren,
  Fragment,
} from 'preact'
import { useContext, useEffect, useState } from 'preact/compat'
import { parseTags } from '~utils'
import { createDefaultPolyglot, updatePolyglot } from './polyglot'
import type { WithLocalisedProps } from '~core/localisation/types'
import type {
  SupportedLanguages,
  LocaleConfig,
  TranslatedTagParser,
} from './types'
import Spinner from 'components/Spinner'
import { getDirection, getSupportLanguage } from './util'
import { defaultLocaleTag } from './languages'

type ProviderProps = {
  language?: SupportedLanguages | LocaleConfig
}

export const LocaleContext = createContext<WithLocalisedProps | undefined>(
  undefined
)

export const LocaleProvider: FunctionComponent<ProviderProps> = ({
  language,
  children,
}) => {
  const [initialLanguage, setInitialLanguage] = useState(language)
  const supportedLanguage = getSupportLanguage(initialLanguage)
  const direction = getDirection(
    typeof language === 'object' ? language : supportedLanguage
  )

  const [polyglot, setPolyglot] = useState(createDefaultPolyglot())
  const [loading, setLoading] = useState(false)

  const translate = polyglot.t.bind(polyglot)
  const parseTranslatedTags: TranslatedTagParser = (key, handler) =>
    parseTags(translate(key), handler)

  const setLanguage = async (
    newLanguage: SupportedLanguages | LocaleConfig
  ) => {
    setLoading(true)

    const newSupportedLanguage = getSupportLanguage(newLanguage)
    let newPolyglot = createDefaultPolyglot()
    newPolyglot = await updatePolyglot(newPolyglot, newSupportedLanguage)

    setInitialLanguage(newLanguage)
    setPolyglot(newPolyglot)
    setLoading(false)
  }

  useEffect(() => {
    if (supportedLanguage !== defaultLocaleTag) {
      setLanguage(supportedLanguage)
    }
  }, [])

  useEffect(() => {
    setLanguage(supportedLanguage)
  }, [supportedLanguage])

  return (
    <LocaleContext.Provider
      value={{
        language: polyglot?.currentLocale,
        direction,
        translate,
        parseTranslatedTags,
        setLanguage,
        loading,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

type LocaleLoaderProps = {
  shouldAutoFocus?: boolean
  children: ComponentChildren
}
export const LocaleLoader = ({
  shouldAutoFocus,
  children,
}: LocaleLoaderProps) => {
  const context = useContext(LocaleContext)

  if (context?.loading) {
    return <Spinner shouldAutoFocus={shouldAutoFocus} />
  }

  return <Fragment>{children}</Fragment>
}

export const useLocales = (): WithLocalisedProps => {
  const context = useContext(LocaleContext)

  if (!context) {
    throw new Error(`LocaleContext hasn't been initialized!`)
  }

  return context
}

export const localised = <P,>(
  WrappedComponent: ComponentType<P & WithLocalisedProps>
): ComponentType<P> => {
  const LocalisedComponent: FunctionComponent<P> = (props) => (
    <LocaleContext.Consumer>
      {(injectedProps) => {
        if (injectedProps == null) {
          throw new Error(`LocaleContext hasn't been initialized!`)
        }

        return <WrappedComponent {...props} {...injectedProps} />
      }}
    </LocaleContext.Consumer>
  )

  return LocalisedComponent
}
