import { poaDocumentTypes } from 'components/DocumentSelector/documentTypes'
import { SdkConfiguration } from '~core/SdkConfiguration/types'
import { ImageQualityValidationPayload } from '~types/api'
import { CountryData, DocumentSides } from '~types/commons'
import { DocumentTypes, PoaTypes } from '~types/steps'
import { isOfMimeType } from '~utils/blob'

// create sdk validation payload to send to the backend.
const getIqsValidationRequestPayload = (
  sdkConfiguration: SdkConfiguration,
  imageQualityRetries: number,
  blob: Blob,
  documentType: DocumentTypes | undefined,
  side: DocumentSides,
  documentCountry: CountryData | undefined,
  poaDocumentType: PoaTypes | undefined
): ImageQualityValidationPayload => {
  const isPoA = poaDocumentType && poaDocumentTypes.includes(poaDocumentType)
  const isRomanianNationalIdBack =
    documentType === 'national_identity_card' &&
    side === 'back' &&
    documentCountry?.country_alpha3 === 'ROU'
  const shouldPerformImageQualityValidations =
    !isOfMimeType(['pdf'], blob) && !isPoA && !isRomanianNationalIdBack
  const shouldDetectDocument = !isPoA
  const shouldReturnErrorForImageQuality =
    imageQualityRetries <= sdkConfiguration.document_capture.max_total_retries
  const imageQualityErrorType = shouldReturnErrorForImageQuality
    ? 'error'
    : 'warn'

  // if the specific document detection (for example, detect_glare) issue happens on the backend:
  //  - if it was requested as an 'error', then an error 422 will be sent from the backend.
  //  - if it was requested as a 'warn', then there will be no HTTP error.
  // If an error is returned from the backend, the idea is to force the user to retry with a new document capture.
  // If a warning is returned, the end user can either retry a new document capture or upload the current document anyway.
  return {
    ...(shouldDetectDocument ? { detect_document: 'error' } : {}),
    ...(shouldPerformImageQualityValidations
      ? {
          detect_cutoff: imageQualityErrorType,
          detect_glare: imageQualityErrorType,
          detect_blur: imageQualityErrorType,
        }
      : {}),
  }
}

export { getIqsValidationRequestPayload }
