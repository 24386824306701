import { Queue, QueueItem } from '~core/util/Queue'
import type {
  EnvironmentType,
  LogData,
  LogLevels,
  OutputInterface,
} from '../types'
import { performHttpRequest } from '~core/Network'
import { reduxStore } from 'components/ReduxAppWrapper'
import {
  createAnalyticsPayload,
  AnalyticsPayload,
} from '~core/Analytics/createAnalyticsPayload'
import { SdkConfiguration } from '~core/SdkConfiguration/types'
import { trackException } from 'Tracker'
import { formatError } from '~utils/onfidoApi'
import { ParsedError } from '~types/api'

let token: string | undefined
let url: string | undefined
let sdkConfiguration: SdkConfiguration | undefined

type LoggerProperties = {
  event_type: 'log'
  log_labels: string[]
  log_level: string
  log_metadata: Record<string, unknown>
}

type LoggerAnalyticsPayload = AnalyticsPayload<LoggerProperties>

export class NetworkOutput implements OutputInterface {
  private queue: Queue<LoggerAnalyticsPayload>
  private waitQueue: Queue<LogData>

  constructor() {
    this.queue = new Queue<LoggerAnalyticsPayload>({
      limit: 1, // Note: Send directly until we find a reliable way to listen to SDK unmount, should be 20.
      paused: true,
    })
    this.queue.on('flush', this.sendToBackend)

    this.waitQueue = new Queue<LogData>({
      limit: 0,
      paused: true,
    })
    this.waitQueue.on('flush', this.transferToQueue)

    this.listenToReduxStore()
  }

  private transferToQueue = (items: QueueItem<LogData>[]) => {
    items
      .map(({ item }) => this.createLogPayload(item))
      .filter(this.filterLog)
      .forEach((log) => {
        this.queue.push(log)
      })
  }

  /* 
    Note: We need to wait for the token, url & sdkConfiguration 
    to be available in the store before sending any network requests
  */
  private listenToReduxStore() {
    reduxStore.subscribe(() => {
      const store = reduxStore.getState()

      token = store.globals.token
      url = store.globals.urls.onfido_api_url
      sdkConfiguration = store.globals.sdkConfiguration

      if (token && url && sdkConfiguration && this.queue.paused) {
        this.waitQueue.resume()
        this.queue.resume()
      }
    })
  }

  private sendToBackend = (items: QueueItem<LoggerAnalyticsPayload>[]) => {
    const events = items.map(({ item }) => item)

    performHttpRequest(
      {
        method: 'POST',
        contentType: 'application/json',
        endpoint: `${url}/v4/sdk/logger`,
        token: `Bearer ${token}`,
        payload: JSON.stringify({ events }),
      },
      this.onNetworkSucces,
      (request) => formatError(request, this.onNetworkError)
    )
  }

  private createLogPayload(data: LogData) {
    return createAnalyticsPayload<LoggerProperties>({
      event: 'log',
      event_time: data.timestamp,
      properties: {
        event_type: 'log',
        log_labels: data.labels,
        log_level: data.level,
        log_metadata: {
          message: data.message,
          metadata: data.metadata,
          file: data.file,
          method: data.method,
          line: data.line,
        },
      },
    })
  }

  private onNetworkSucces() {}
  private onNetworkError = (error: ParsedError) => {
    trackException('Could not send logger payload to backend', {
      data: { status: error.status },
    })
  }

  private filterLog(data: LoggerAnalyticsPayload): boolean {
    const enabled = sdkConfiguration?.sdk_features?.logger?.enabled || false
    const enabledLevels = sdkConfiguration?.sdk_features?.logger?.levels || []

    if (
      enabled &&
      enabledLevels.indexOf(data.properties.log_level as LogLevels) > -1
    ) {
      return true
    }
    return false
  }

  public write = (data: LogData, environment?: EnvironmentType) => {
    if (environment !== 'production') {
      return
    }

    // In case sdkConfiguration is not loaded: add any logs to the waitQueue.
    // they will be filtered when the sdkConfiguration is available.
    if (!sdkConfiguration) {
      this.waitQueue.push(data)
      return
    }

    const payload = this.createLogPayload(data)

    if (this.filterLog(payload)) {
      this.queue.push(payload)
    }
  }
}
