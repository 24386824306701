import { h } from 'preact'

const Loader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56 56"
      className="onfidoLoaderSvg"
    >
      <path
        className="left"
        d="M11.47 17.04c-.29-.49-.6-.95-.92-1.41-.22-.32-.67-.36-.95-.1-1.33 1.25-2.63 2.6-3.86 4.04-1.27 1.49-1.77 3.5-1.35 5.42.46 2.04 1.01 4.01 1.69 5.89 1 2.8 2.28 5.49 3.78 8.01.23.39.48.78.73 1.16l.13.2c.17.25.55.25.71-.02 4.17-6.88 4.47-15.79.04-23.19z"
        fill="#99a3f4"
      />
      <path
        className="top"
        d="M38.96 11.47c.49-.29.95-.6 1.41-.92.32-.22.36-.67.1-.95-1.25-1.33-2.6-2.63-4.04-3.86-1.49-1.27-3.5-1.77-5.42-1.35-2.04.46-4.01 1.01-5.89 1.69-2.8 1-5.49 2.28-8.01 3.78-.39.23-.78.48-1.16.73l-.2.13c-.25.17-.25.55.02.71 6.88 4.17 15.79 4.47 23.19.04z"
        fill="#99a3f4"
      />
      <path
        className="right"
        d="M45.45 40.37c-.32-.46-.63-.92-.92-1.41-4.43-7.39-4.13-16.31.03-23.2.16-.27.54-.27.71-.02.04.07.09.13.13.2.25.38.5.77.73 1.16 1.5 2.52 2.79 5.21 3.78 8.01.68 1.88 1.24 3.85 1.69 5.89.43 1.92-.08 3.93-1.35 5.42-1.24 1.44-2.53 2.79-3.86 4.04-.27.27-.72.22-.94-.09z"
        fill="#99a3f4"
      />
      <path
        d="M28.087 19.58c-1.782-.003-3.519.56-4.961 1.607-1.441 1.046-2.514 2.524-3.063 4.219-.549 1.695-.546 3.521.008 5.214.554 1.693 1.632 3.167 3.077 4.209 1.445 1.042 3.184 1.599 4.965 1.591 1.782.003 3.519-.56 4.961-1.606 1.441-1.047 2.514-2.525 3.063-4.22.549-1.695.546-3.521-.008-5.214-.554-1.693-1.632-3.167-3.077-4.209-1.445-1.042-3.184-1.599-4.965-1.591zM15.73 45.45c.46-.32.92-.63 1.41-.92 7.4-4.43 16.31-4.13 23.2.03.27.16.27.54.02.71-.07.04-.13.09-.2.13-.38.25-.77.5-1.16.73-2.52 1.5-5.21 2.79-8.01 3.78-1.88.68-3.85 1.24-5.89 1.69-1.92.43-3.93-.08-5.42-1.35-1.44-1.24-2.79-2.53-4.04-3.86-.27-.27-.23-.72.09-.94z"
        fill="#3640f5"
      />
    </svg>
  )
}

export default Loader
