import { useState, useEffect } from 'preact/hooks'
import { isAndroidUa, isDesktop } from '~utils'
import { backCameraKeywords } from '~webcam/utils'

const hasMultipleFocusMode = (stream: MediaStream) => {
  const track = stream.getVideoTracks()[0]
  const capabilities = track.getCapabilities()
  // @ts-ignore
  return capabilities.focusMode && capabilities.focusMode.length > 1
}

const getDeviceId = (stream: MediaStream) => {
  const track = stream.getVideoTracks()[0]
  const settings = track.getSettings()
  return settings.deviceId
}

const isBackCamera = (label: string) => {
  return backCameraKeywords.some((keyword) =>
    label.toLowerCase().includes(keyword)
  )
}

const stop = (stream: MediaStream) => {
  stream.getVideoTracks().forEach((track) => track.stop())
}

const getStream = (deviceId?: string) => {
  return navigator.mediaDevices.getUserMedia({
    audio: false,
    video: getConstraints(deviceId),
  })
}

const getConstraints = (deviceId?: string): MediaTrackConstraints => {
  if (isDesktop) {
    // debug for us devs
    return {
      width: 1280,
      aspectRatio: 1.33,
    }
  }
  if (isAndroidUa(navigator.userAgent)) {
    // on some phones if we don't set both width and height, the resolution is very poor, then you need to "Zoom out" when taking a picture
    return {
      deviceId,
      height: 1080,
      width: 1920,
      facingMode: 'environment',
    }
  }

  // IOS. DONT SET HEIGHT. If you do, IPhones 13 and 14 will bug and take pictures with too much brightness
  return {
    deviceId,
    width: 1920,
    facingMode: 'environment',
  }
}

const triggerPermissions = () => {
  return getStream().then((stream) => {
    stop(stream)
  })
}

export const _useVideoConstraintsInternal = (
  setDeviceId: (deviceId: string | undefined) => void,
  setCameraSelected: (selected: true) => void,
  onFailure?: (error?: Error) => void
) => {
  triggerPermissions()
    .then(() => {
      if (isAndroidUa(navigator.userAgent)) {
        return navigator.mediaDevices.enumerateDevices().then((devices) => {
          const candidates = devices
            .sort((a, b) => (a.label < b.label ? -1 : 1))
            .filter((device) => device.kind === 'videoinput')
            .filter((device) => isBackCamera(device.label))

          // Select Camera0 which is most of the time the good one on Android
          const camera0 = candidates.find((device) =>
            device.label.includes(' 0,')
          )

          // Test the camera
          return getStream(camera0 && camera0.deviceId).then((stream) => {
            // If the camera 0 do not have multiple focus mode, we remove it from the candidates list
            if (!hasMultipleFocusMode(stream)) {
              const currentDeviceId = getDeviceId(stream)
              const fallback = candidates.filter(
                ({ deviceId }) => deviceId !== currentDeviceId
              )[0]
              setDeviceId(fallback && fallback.deviceId)
            } else {
              setDeviceId(camera0 && camera0.deviceId)
            }

            stop(stream)
            setCameraSelected(true)
          })
        })
      }
      setCameraSelected(true)
    })
    .catch((e) => {
      setCameraSelected(true)
      onFailure && onFailure(e)
    })
}

/**
 *  Selects the best front camera (via deviceId) for document capture, and requests the 1920x1440 resolution.
 * @returns the Video constraints
 */
export const useVideoConstraints = (onFailure?: (error?: Error) => void) => {
  const [deviceId, setDeviceId] = useState<string | undefined>()
  const [cameraSelected, setCameraSelected] = useState(false)

  useEffect(() => {
    _useVideoConstraintsInternal(setDeviceId, setCameraSelected, onFailure)
  }, [])

  return {
    videoConstraints: cameraSelected ? getConstraints(deviceId) : undefined,
  }
}
